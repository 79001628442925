import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedStateService {
  private _fromLoginPage = new BehaviorSubject<boolean>(true); // Default value
  public fromLoginPage$ = this._fromLoginPage.asObservable();

  setFromLoginPage(value: boolean): void {
    this._fromLoginPage.next(value);
  }

  getFromLoginPage(): boolean {
    return this._fromLoginPage.getValue();
  }
}
