import { Injectable } from '@angular/core';
import { BigNumber, Contract } from 'ethers';
import { AMATES_NFT_CONTRACT_ABI } from '../abis/amates-nft-contract.abi';
import {
  AmatesCrowdToken,
  IMintAmatesNFTReqMdl,
  IMintAmatesNFTRespMdl,
  IPlan,
  MintStatus,
  MintSteps
} from '../models';
import {
  Conversion,
  CrowdToken,
  MainNetworksById,
  PriceService
} from '@crowdswap/constant';
import { AMATES_PLAN_FACTORY_CONTRACT_ABI } from '../abis/amates-plan-factory-contract.abi';
import { formatNumber, NumberType } from '@uniswap/conedison/format.js';
import { CrowdWalletService } from './crowd-wallet.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  NftContractAddress,
  PlanFactoryContractAddress
} from '../constant/constant';
import { NftTypes, Rarities } from '../shared.enum';

export const Mint_Address = [
  environment.WalletUrl,
  'account/mint-amates-nft?mustExecute=true'
].join('/');

@Injectable()
export class NftService {
  private readonly marketAssetsPath = 'assets/media/market/';
  public nftAddress = NftContractAddress;
  public nftAbi = AMATES_NFT_CONTRACT_ABI;
  public planFactoryAbi = AMATES_PLAN_FACTORY_CONTRACT_ABI;
  public planFactoryAddress = PlanFactoryContractAddress;

  constructor(
    private readonly priceService: PriceService,
    private readonly http: HttpClient,
    private readonly crowdWalletService: CrowdWalletService
  ) {}

  private getContract(address: string, abi: any) {
    return new Contract(
      address,
      abi,
      this.crowdWalletService.getNetworkProvider(137)
    );
  }

  async createPlan() {
    const contract = this.getContract(
      this.planFactoryAddress,
      this.planFactoryAbi
    );
    // Define the parameters for the new plan
    const planName = 'Striker';
    const minPower = 35;
    const maxPower = 40;
    const minSpeed = 55;
    const maxSpeed = 80;
    const minIntelligence = 35;
    const maxIntelligence = 45;
    const minAgility = 40;
    const maxAgility = 55;
    const price = 7;
    const imageUrl = 'https://asset.crowdswap.org/amates/striker.png';

    try {
      const transaction = await contract.populateTransaction.createPlan(
        planName,
        minPower,
        maxPower,
        minSpeed,
        maxSpeed,
        minIntelligence,
        maxIntelligence,
        minAgility,
        maxAgility,
        price,
        imageUrl
      );
      // Call the createPlan method

      if (transaction) {
        transaction.gasLimit = BigNumber.from(0x069d63);
        transaction.from = this.crowdWalletService.getWalletAddress();
        await this.crowdWalletService.sendTransaction(transaction);
      }
      const tx = await contract.createPlan(
        planName,
        minPower,
        maxPower,
        minSpeed,
        maxSpeed,
        minIntelligence,
        maxIntelligence,
        minAgility,
        maxAgility,
        price,
        imageUrl
      );

      console.log('Transaction submitted. Waiting for confirmation...');

      // Wait for the transaction to be confirmed
      await tx.wait();

      console.log('Plan created successfully!');
    } catch (error) {
      console.error('Failed to create plan:', error);
    }
  }

  async updatePlan() {
    const contract = this.getContract(
      this.planFactoryAddress,
      this.planFactoryAbi
    );
    // Define the parameters for the new plan
    const planName = 'Striker';
    const minPower = 35;
    const maxPower = 40;
    const minSpeed = 55;
    const maxSpeed = 80;
    const minIntelligence = 35;
    const maxIntelligence = 45;
    const minAgility = 40;
    const maxAgility = 55;
    const price = 7;
    const imageUrl = 'https://asset.crowdswap.org/amates/striker.png';

    try {
      const transaction = await contract.populateTransaction.updatePlan(
        1,
        planName,
        minPower,
        maxPower,
        minSpeed,
        maxSpeed,
        minIntelligence,
        maxIntelligence,
        minAgility,
        maxAgility,
        price,
        imageUrl
      );
      // Call the createPlan method

      if (transaction) {
        transaction.gasLimit = BigNumber.from(0x069d63);
        transaction.from = this.crowdWalletService.getWalletAddress();
        await this.crowdWalletService.sendTransaction(transaction);
      }
      const tx = await contract.updatePlan(
        1,
        planName,
        minPower,
        maxPower,
        minSpeed,
        maxSpeed,
        minIntelligence,
        maxIntelligence,
        minAgility,
        maxAgility,
        price,
        imageUrl
      );

      console.log('Transaction submitted. Waiting for confirmation...');

      // Wait for the transaction to be confirmed
      await tx.wait();

      console.log('Plan created successfully!');
    } catch (error) {
      console.error('Failed to create plan:', error);
    }
  }

  async mintAssistant(planId: number) {
    const assistantPlan = await this.getPlanById(planId);

    const contract = this.getContract(
      this.planFactoryAddress,
      this.planFactoryAbi
    );

    try {
      const transaction = await contract.populateTransaction.buyNft([planId]);
      // Call the createPlan method

      if (transaction) {
        transaction.gasLimit = BigNumber.from(0x069d63);
        transaction.from = this.crowdWalletService.getWalletAddress();
        await this.crowdWalletService.sendTransaction(transaction);
      }
      const tx = await contract.mintFromPlan([assistantPlan.index]);

      console.log('Transaction submitted. Waiting for confirmation...');

      // Wait for the transaction to be confirmed
      await tx.wait();

      console.log('Plan created successfully!');
    } catch (error) {
      console.error('Failed to create plan:', error);
    }
  }

  public async mintAmatesNFT(
    planId: number,
    quantity: number
  ): Promise<IMintAmatesNFTRespMdl> {
    const userNFTs = await this.getUserNFTs();

    try {
      const data: IMintAmatesNFTReqMdl = {
        planId,
        quantity
      };

      const respData = <any>(
        await this.http
          .post(Mint_Address, data, this.crowdWalletService.setHeader())
          .toPromise()
      );

      let result: IMintAmatesNFTRespMdl = {
        status: MintStatus.success,
        transactionHash: respData.result
      };

      return result;
    } catch (error: any) {
      console.log(error);
      const newUserNFTs = await this.getUserNFTs();
      if (newUserNFTs.length > userNFTs.length) {
        return {
          status: MintStatus.success,
          transactionHash: undefined
        };
      }

      return { msg: error.message, status: MintStatus.error };
    }
  }

  async getAllAssistantPlans(): Promise<IPlan[]> {
    try {
      const contract = this.getContract(
        this.planFactoryAddress,
        this.planFactoryAbi
      );

      const result = await contract.getNftTypeStats();

      const plans: IPlan[] = result.map((item, index) => {
        const price = Conversion.convertStringFromDecimal(
          item.price.toString(),
          6
        );

        const modifyAvatarImageUrl = (imageUrl: string): string => {
          const url = new URL(imageUrl);
          const parts = url.pathname.split('/');
          const fileName = parts.pop() || ''; // Extract the file name
          const newFileName = `avatar_${fileName}`; // Add 'avatar_' prefix
          parts.push(newFileName);
          url.pathname = parts.join('/');
          return url.toString();
        };

        const allTypes = {
          planId: item.nftType,
          name: NftTypes[item.nftType],
          cardImgPath: item.imageUrl,
          bannerImgPath: item.imageUrl,
          avatarImageUrl: modifyAvatarImageUrl(item.imageUrl),
          agility: {
            max: (item.agility.max.toString() / 100).toFixed(2),
            min: (item.agility.min.toString() / 100).toFixed(2)
          },
          intelligence: {
            max: (item.intelligence.max.toString() / 100).toFixed(2),
            min: (item.intelligence.min.toString() / 100).toFixed(2)
          },
          power: {
            max: (item.power.max.toString() / 100).toFixed(2),
            min: (item.power.min.toString() / 100).toFixed(2)
          },
          speed: {
            max: (item.speed.max.toString() / 100).toFixed(2),
            min: (item.speed.min.toString() / 100).toFixed(2)
          },
          mintSteps: MintSteps.Init,
          Price: price,
          PriceToDisplay: Conversion.adjustFraction(parseFloat(price), 6),
          automations: 0,
          fuelEfficiency: 0,
          repairable: false,
          qtyMint: 1,
          shareable: false,
          tearFactor: 0,
          walletAddress: 'test'
        } as IPlan;

        return allTypes;
      });

      return plans;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  public async getPlanById(planId: number) {
    const contract = this.getContract(
      this.planFactoryAddress,
      this.planFactoryAbi
    );
    const plan = await contract.plans(planId);

    return plan;
  }

  public async getPlanByNftTypeAndRarity(nftType: number, rarity: number) {
    const contract = this.getContract(
      this.planFactoryAddress,
      this.planFactoryAbi
    );
    const plan = await contract.getPlanByNftTypeAndRarity(nftType, rarity);

    return plan;
  }

  async getUserNFTs(): Promise<BigNumber[]> {
    try {
      const userAddress = this.crowdWalletService.getWalletAddress();
      const nftContract = this.getContract(this.nftAddress, this.nftAbi);
      const userNFTs = <BigNumber[]>await nftContract.getUserNFTs(userAddress);

      return userNFTs;
    } catch (error) {
      console.error('Error fetching getUserNFTs:', error);
      return [];
    }
  }

  async getUserAssistants(): Promise<IPlan[]> {
    try {
      const nftContract = this.getContract(this.nftAddress, this.nftAbi);
      const userAddress = this.crowdWalletService.getWalletAddress();

      const userNFTs = await nftContract.getUserNFTDetails(userAddress);

      const modifyAvatarImageUrl = (imageUrl: string): string => {
        const url = new URL(imageUrl);
        const parts = url.pathname.split('/');
        const fileName = parts.pop() || ''; // Extract the file name
        const newFileName = `avatar_${fileName}`; // Add 'avatar_' prefix
        parts.push(newFileName);
        url.pathname = parts.join('/');
        return url.toString();
      };

      const userAssistants: IPlan[] = [];
      for (const nft of userNFTs) {
        const planDetails = await this.getPlanByNftTypeAndRarity(
          nft.nftType,
          nft.rarity
        );

        const price = Conversion.convertStringFromDecimal(
          planDetails.price.toString(),
          6
        );

        userAssistants.push({
          name: NftTypes[nft.nftType],
          rarityName: Rarities[nft.rarity],
          power: {
            active: parseFloat((nft.power.toString() / 100).toFixed(2))
          },
          speed: {
            active: parseFloat((nft.speed.toString() / 100).toFixed(2))
          },
          intelligence: {
            active: parseFloat((nft.intelligence.toString() / 100).toFixed(2))
          },
          agility: {
            active: parseFloat((nft.agility.toString() / 100).toFixed(2))
          },
          cardImgPath: planDetails.imageUrl,
          avatarImageUrl: modifyAvatarImageUrl(planDetails.imageUrl), // Updated field
          bannerImgPath: planDetails.imageUrl,
          planId: parseInt(planDetails.planId.toString()),
          tokenId: parseInt(nft.tokenId),
          Price: price,
          PriceToDisplay: Conversion.adjustFraction(parseFloat(price), 6),
          automations: 0,
          fuelEfficiency: 0,
          repairable: false,
          qtyMint: 1,
          shareable: false,
          tearFactor: 0,
          walletAddress: 'test',
          mintSteps: MintSteps.Init,
          rarity: nft.rarity
        } as IPlan);
      }

      return userAssistants;
    } catch (error) {
      console.error('Error fetching NFT details:', error);
      return [];
    }
  }

  public async addBalanceToTokens(
    tokens: any,
    withBalance: boolean = false
  ): Promise<AmatesCrowdToken[]> {
    const promises: any[] = [];
    let result: any[] = [];

    for (const chainId in MainNetworksById) {
      if (environment.ACTIVE_NETWORK.includes(chainId)) {
        let tempTokens = tokens.filter(
          (token: CrowdToken) => token.chainId.toString() === chainId
        );
        promises.push(this.crowdWalletService.addBalanceToTokens(tempTokens));
      }
    }

    let promiseResult = await Promise.allSettled(promises);
    promiseResult.forEach((item, index, array) => {
      if (item && item.status === 'fulfilled' && item.value.length > 0) {
        const list = item.value.filter((x) => parseFloat(x.balance) !== 0);
        result.push(...list);
      }
    });

    if (withBalance) {
      result = result.filter((token: CrowdToken) => {
        return token.balance && token.balance != '' && +token.balance > 0;
      });
    }

    result.forEach(async (item: AmatesCrowdToken) => {
      try {
        item.price = await this.priceService.getPrice(
          item,
          this.crowdWalletService.getNetworkProvider(item.chainId)
        );
        item.value = (
          parseFloat(item.balance) * parseFloat(item.price ?? '0')
        ).toString();
        item.balanceToDisplayInUSDT = formatNumber(
          parseFloat(item.value),
          NumberType.FiatTokenPrice
        );
      } catch (e) {
        console.log(e);
      }
    });

    return result;
  }
}
