import { Injectable } from "@angular/core";
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { CrowdWalletService } from "./crowd-wallet.service";
import { IPermissionGuardModel } from "../models";

@Injectable()
export class PermissionGuard {
  constructor(
    private readonly router: Router,
    private readonly connectWalletService: CrowdWalletService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userData = this.connectWalletService.userDetail;

    const data = route.data.Permission as IPermissionGuardModel;
    if (
      !userData ||
      (userData && (!userData.email || !userData.access_token))
    ) {

      if (data.RedirectTo && data.RedirectTo !== undefined) {
        this.router.navigate([data.RedirectTo]);
      }

      return false;
    }
    if (this.connectWalletService.isTokenExpired()) {
      this.connectWalletService.deleteUserData()
      this.router.navigate([data.RedirectTo]);
      return false;
    }

    return true;
  }
}
