export enum ActivePage {
  Design,
  Market,
  Wallet,
  Setting
}

export enum SlideNames {
  QrCode,
  VerificationCode,
  Set2Fa,
  SaveChanges,
  SuccessfulSaveChanges
}

export const Rarities = {
  '0': 'Common',
  '1': 'Uncommon',
  '2': 'Rare',
  '3': 'Epic',
  '4': 'Legendary',
  '5': 'Mystical'
};

export const NftTypes = {
  '0': 'Nova',
  '1': 'Wheeler',
  '2': 'Titan',
  '3': 'Viper'
};

export enum SortBy {
  STATUS,
  DATE
}
