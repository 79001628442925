import { BigNumber } from 'ethers';
export enum MarketTab {
  MARKET,
  YOURNFT
}

export enum MintSteps {
  Init,

  SelectToken,
  ConfirmTransaction,

  CheckTransactionHash,

  Wating,
  TransactionResult
}

export interface IRange {
  min?: string;
  max?: string;
  active?: number;
}

export interface IPlan {
  name: string;
  rarityName: string;
  cardImgPath: string;
  bannerImgPath: string;
  avatarImageUrl: string;
  power: IRange;
  speed: IRange;
  intelligence: IRange;
  agility: IRange;
  shareable: boolean;
  repairable: boolean;
  automations: number;
  fuelEfficiency: number;
  tearFactor: number;
  Price: string;
  PriceToDisplay: string;
  qtyMint: number;
  walletAddress: string;
  mintSteps: MintSteps;
  planId: number;
  tokenId: number;
  rarity: number;
}

export interface AssistantDetails {
  index: number;
  name: string;
  speed: number;
  power: number;
  intelligence: number;
  agility: number;
  imageUrl: string;
}

export interface AssistantPlan {
  index: number;
  name: string;
  minSpeed: number;
  maxSpeed: number;
  minPower: number;
  maxPower: number;
  minIntelligence: number;
  maxIntelligence: number;
  minAgility: number;
  maxAgility: number;
  price: number;
  imageUrl: string;
}

export interface IMintAmatesNFTReqMdl {
  planId: number;
  quantity: number;
}

export interface IMintAmatesNFTRespMdl {
  status: string;
  msg?: string;
  transactionHash?: string;
}

export enum MintStatus {
  success = 'success',
  error = 'error'
}

export interface ISelectNFT {
  plan: IPlan;
  index?: number;
  reset?: boolean;
}
