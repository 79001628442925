export const AMATES_PLAN_FACTORY_CONTRACT_ABI = [
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "previousAdmin",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "newAdmin",
        "type": "address"
      }
    ],
    "name": "AdminChanged",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "beacon",
        "type": "address"
      }
    ],
    "name": "BeaconUpgraded",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint8",
        "name": "version",
        "type": "uint8"
      }
    ],
    "name": "Initialized",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint32",
        "name": "planId",
        "type": "uint32"
      },
      {
        "indexed": false,
        "internalType": "enum NftMintingLib.NftTypes",
        "name": "nftType",
        "type": "uint8"
      },
      {
        "indexed": false,
        "internalType": "enum NftMintingLib.Rarities",
        "name": "rarity",
        "type": "uint8"
      }
    ],
    "name": "PlanCreated",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint32",
        "name": "planId",
        "type": "uint32"
      }
    ],
    "name": "PlanDeleted",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint32",
        "name": "planId",
        "type": "uint32"
      },
      {
        "indexed": false,
        "internalType": "enum NftMintingLib.NftTypes",
        "name": "nftType",
        "type": "uint8"
      },
      {
        "indexed": false,
        "internalType": "enum NftMintingLib.Rarities",
        "name": "rarity",
        "type": "uint8"
      }
    ],
    "name": "PlanUpdated",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "revenueAddress",
        "type": "address"
      }
    ],
    "name": "RevenueAddressSet",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "implementation",
        "type": "address"
      }
    ],
    "name": "Upgraded",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "uint32",
        "name": "planId",
        "type": "uint32"
      },
      {
        "internalType": "uint32",
        "name": "totalSupply",
        "type": "uint32"
      },
      {
        "internalType": "uint32",
        "name": "mintedSupply",
        "type": "uint32"
      }
    ],
    "name": "UpdateTotalAndMinted",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "allPlanIds",
    "outputs": [
      {
        "internalType": "uint32",
        "name": "",
        "type": "uint32"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "boxContractAddress",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "enum NftMintingLib.NftTypes",
        "name": "nftType",
        "type": "uint8"
      },
      {
        "internalType": "uint32",
        "name": "amount",
        "type": "uint32"
      }
    ],
    "name": "buyNftFromName",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "claimOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "components": [
          {
            "internalType": "enum NftMintingLib.NftTypes",
            "name": "nftType",
            "type": "uint8"
          },
          {
            "internalType": "enum NftMintingLib.Rarities",
            "name": "rarity",
            "type": "uint8"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "min",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "max",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.AttributeRange",
            "name": "speedRange",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "min",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "max",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.AttributeRange",
            "name": "powerRange",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "min",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "max",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.AttributeRange",
            "name": "agilityRange",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "min",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "max",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.AttributeRange",
            "name": "intelligenceRange",
            "type": "tuple"
          },
          {
            "internalType": "uint256",
            "name": "price",
            "type": "uint256"
          },
          {
            "internalType": "string",
            "name": "imageUrl",
            "type": "string"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "totalSupply",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "remainedSupply",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "boxSupply",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "minted",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.Supplies",
            "name": "supplies",
            "type": "tuple"
          },
          {
            "internalType": "string",
            "name": "className",
            "type": "string"
          }
        ],
        "internalType": "struct NftMintingLib.PlanInput[]",
        "name": "planInputs",
        "type": "tuple[]"
      }
    ],
    "name": "createPlans",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint32",
        "name": "planId",
        "type": "uint32"
      }
    ],
    "name": "deletePlan",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getAllPlans",
    "outputs": [
      {
        "components": [
          {
            "internalType": "enum NftMintingLib.NftTypes",
            "name": "nftType",
            "type": "uint8"
          },
          {
            "internalType": "enum NftMintingLib.Rarities",
            "name": "rarity",
            "type": "uint8"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "min",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "max",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.AttributeRange",
            "name": "speed",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "min",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "max",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.AttributeRange",
            "name": "power",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "min",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "max",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.AttributeRange",
            "name": "agility",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "min",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "max",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.AttributeRange",
            "name": "intelligence",
            "type": "tuple"
          },
          {
            "internalType": "uint256",
            "name": "price",
            "type": "uint256"
          },
          {
            "internalType": "string",
            "name": "imageUrl",
            "type": "string"
          },
          {
            "internalType": "uint32",
            "name": "planId",
            "type": "uint32"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "totalSupply",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "remainedSupply",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "boxSupply",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "minted",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.Supplies",
            "name": "supplies",
            "type": "tuple"
          },
          {
            "internalType": "string",
            "name": "className",
            "type": "string"
          }
        ],
        "internalType": "struct NftMintingLib.PlanAttributes[]",
        "name": "",
        "type": "tuple[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getNftTypeStats",
    "outputs": [
      {
        "components": [
          {
            "internalType": "enum NftMintingLib.NftTypes",
            "name": "nftType",
            "type": "uint8"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "min",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "max",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.AttributeRange",
            "name": "speed",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "min",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "max",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.AttributeRange",
            "name": "power",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "min",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "max",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.AttributeRange",
            "name": "agility",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "min",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "max",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.AttributeRange",
            "name": "intelligence",
            "type": "tuple"
          },
          {
            "internalType": "uint256",
            "name": "price",
            "type": "uint256"
          },
          {
            "internalType": "string",
            "name": "imageUrl",
            "type": "string"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "totalSupply",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "remainedSupply",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "boxSupply",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "minted",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.Supplies",
            "name": "supplies",
            "type": "tuple"
          },
          {
            "internalType": "string",
            "name": "className",
            "type": "string"
          }
        ],
        "internalType": "struct NftMintingLib.NftTypeAttributes[]",
        "name": "",
        "type": "tuple[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint32",
        "name": "planId",
        "type": "uint32"
      }
    ],
    "name": "getPlan",
    "outputs": [
      {
        "components": [
          {
            "internalType": "enum NftMintingLib.NftTypes",
            "name": "nftType",
            "type": "uint8"
          },
          {
            "internalType": "enum NftMintingLib.Rarities",
            "name": "rarity",
            "type": "uint8"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "min",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "max",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.AttributeRange",
            "name": "speed",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "min",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "max",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.AttributeRange",
            "name": "power",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "min",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "max",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.AttributeRange",
            "name": "agility",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "min",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "max",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.AttributeRange",
            "name": "intelligence",
            "type": "tuple"
          },
          {
            "internalType": "uint256",
            "name": "price",
            "type": "uint256"
          },
          {
            "internalType": "string",
            "name": "imageUrl",
            "type": "string"
          },
          {
            "internalType": "uint32",
            "name": "planId",
            "type": "uint32"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "totalSupply",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "remainedSupply",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "boxSupply",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "minted",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.Supplies",
            "name": "supplies",
            "type": "tuple"
          },
          {
            "internalType": "string",
            "name": "className",
            "type": "string"
          }
        ],
        "internalType": "struct NftMintingLib.PlanAttributes",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "enum NftMintingLib.NftTypes",
        "name": "nftType",
        "type": "uint8"
      },
      {
        "internalType": "enum NftMintingLib.Rarities",
        "name": "rarity",
        "type": "uint8"
      }
    ],
    "name": "getPlanByNftTypeAndRarity",
    "outputs": [
      {
        "components": [
          {
            "internalType": "enum NftMintingLib.NftTypes",
            "name": "nftType",
            "type": "uint8"
          },
          {
            "internalType": "enum NftMintingLib.Rarities",
            "name": "rarity",
            "type": "uint8"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "min",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "max",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.AttributeRange",
            "name": "speed",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "min",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "max",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.AttributeRange",
            "name": "power",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "min",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "max",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.AttributeRange",
            "name": "agility",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "min",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "max",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.AttributeRange",
            "name": "intelligence",
            "type": "tuple"
          },
          {
            "internalType": "uint256",
            "name": "price",
            "type": "uint256"
          },
          {
            "internalType": "string",
            "name": "imageUrl",
            "type": "string"
          },
          {
            "internalType": "uint32",
            "name": "planId",
            "type": "uint32"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "totalSupply",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "remainedSupply",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "boxSupply",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "minted",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.Supplies",
            "name": "supplies",
            "type": "tuple"
          },
          {
            "internalType": "string",
            "name": "className",
            "type": "string"
          }
        ],
        "internalType": "struct NftMintingLib.PlanAttributes",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_paymentToken",
        "type": "address"
      }
    ],
    "name": "initialize",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "enum NftMintingLib.NftTypes",
        "name": "nftType",
        "type": "uint8"
      },
      {
        "internalType": "address",
        "name": "user",
        "type": "address"
      }
    ],
    "name": "mintNftFromNameForBox",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "nftContractAddress",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "paymentToken",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "pendingOwner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint32",
        "name": "",
        "type": "uint32"
      }
    ],
    "name": "plans",
    "outputs": [
      {
        "internalType": "enum NftMintingLib.NftTypes",
        "name": "nftType",
        "type": "uint8"
      },
      {
        "internalType": "enum NftMintingLib.Rarities",
        "name": "rarity",
        "type": "uint8"
      },
      {
        "components": [
          {
            "internalType": "uint32",
            "name": "min",
            "type": "uint32"
          },
          {
            "internalType": "uint32",
            "name": "max",
            "type": "uint32"
          }
        ],
        "internalType": "struct NftMintingLib.AttributeRange",
        "name": "speed",
        "type": "tuple"
      },
      {
        "components": [
          {
            "internalType": "uint32",
            "name": "min",
            "type": "uint32"
          },
          {
            "internalType": "uint32",
            "name": "max",
            "type": "uint32"
          }
        ],
        "internalType": "struct NftMintingLib.AttributeRange",
        "name": "power",
        "type": "tuple"
      },
      {
        "components": [
          {
            "internalType": "uint32",
            "name": "min",
            "type": "uint32"
          },
          {
            "internalType": "uint32",
            "name": "max",
            "type": "uint32"
          }
        ],
        "internalType": "struct NftMintingLib.AttributeRange",
        "name": "agility",
        "type": "tuple"
      },
      {
        "components": [
          {
            "internalType": "uint32",
            "name": "min",
            "type": "uint32"
          },
          {
            "internalType": "uint32",
            "name": "max",
            "type": "uint32"
          }
        ],
        "internalType": "struct NftMintingLib.AttributeRange",
        "name": "intelligence",
        "type": "tuple"
      },
      {
        "internalType": "uint256",
        "name": "price",
        "type": "uint256"
      },
      {
        "internalType": "string",
        "name": "imageUrl",
        "type": "string"
      },
      {
        "internalType": "uint32",
        "name": "planId",
        "type": "uint32"
      },
      {
        "components": [
          {
            "internalType": "uint32",
            "name": "totalSupply",
            "type": "uint32"
          },
          {
            "internalType": "uint32",
            "name": "remainedSupply",
            "type": "uint32"
          },
          {
            "internalType": "uint32",
            "name": "boxSupply",
            "type": "uint32"
          },
          {
            "internalType": "uint32",
            "name": "minted",
            "type": "uint32"
          }
        ],
        "internalType": "struct NftMintingLib.Supplies",
        "name": "supplies",
        "type": "tuple"
      },
      {
        "internalType": "string",
        "name": "className",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "proxiableUUID",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "",
        "type": "bytes32"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "revenueAddress",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "enum NftMintingLib.Rarities[]",
        "name": "rarities",
        "type": "uint8[]"
      },
      {
        "components": [
          {
            "internalType": "uint32",
            "name": "lowerBound",
            "type": "uint32"
          },
          {
            "internalType": "uint32",
            "name": "upperBound",
            "type": "uint32"
          }
        ],
        "internalType": "struct NftMintingLib.StatsRange[]",
        "name": "ranges",
        "type": "tuple[]"
      }
    ],
    "name": "setAllStatsRanges",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_boxContractAddress",
        "type": "address"
      }
    ],
    "name": "setBoxContract",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_nftContractAddress",
        "type": "address"
      }
    ],
    "name": "setNFTContractAddress",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_paymentToken",
        "type": "address"
      }
    ],
    "name": "setPaymentTokenAddress",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_revenueAddress",
        "type": "address"
      }
    ],
    "name": "setRevenueAddress",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "enum NftMintingLib.Rarities",
        "name": "",
        "type": "uint8"
      }
    ],
    "name": "statsRanges",
    "outputs": [
      {
        "internalType": "uint32",
        "name": "lowerBound",
        "type": "uint32"
      },
      {
        "internalType": "uint32",
        "name": "upperBound",
        "type": "uint32"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint32[]",
        "name": "planIds",
        "type": "uint32[]"
      },
      {
        "components": [
          {
            "internalType": "enum NftMintingLib.NftTypes",
            "name": "nftType",
            "type": "uint8"
          },
          {
            "internalType": "enum NftMintingLib.Rarities",
            "name": "rarity",
            "type": "uint8"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "min",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "max",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.AttributeRange",
            "name": "speedRange",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "min",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "max",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.AttributeRange",
            "name": "powerRange",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "min",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "max",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.AttributeRange",
            "name": "agilityRange",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "min",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "max",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.AttributeRange",
            "name": "intelligenceRange",
            "type": "tuple"
          },
          {
            "internalType": "uint256",
            "name": "price",
            "type": "uint256"
          },
          {
            "internalType": "string",
            "name": "imageUrl",
            "type": "string"
          },
          {
            "components": [
              {
                "internalType": "uint32",
                "name": "totalSupply",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "remainedSupply",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "boxSupply",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "minted",
                "type": "uint32"
              }
            ],
            "internalType": "struct NftMintingLib.Supplies",
            "name": "supplies",
            "type": "tuple"
          },
          {
            "internalType": "string",
            "name": "className",
            "type": "string"
          }
        ],
        "internalType": "struct NftMintingLib.PlanInput[]",
        "name": "planInputs",
        "type": "tuple[]"
      }
    ],
    "name": "updatePlans",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newImplementation",
        "type": "address"
      }
    ],
    "name": "upgradeTo",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newImplementation",
        "type": "address"
      },
      {
        "internalType": "bytes",
        "name": "data",
        "type": "bytes"
      }
    ],
    "name": "upgradeToAndCall",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  }
];